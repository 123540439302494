import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Product } from 'src/app/data/Product';
import { AppState } from 'src/app/Redux/reducers';
import { selectProduct } from 'src/app/Redux/selectors/product.selectors';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import {
  calcAge,
  getProperBmiRange,
  isElder,
} from 'src/app/Utils/validatorsUtils';

@Component({
  selector: 'app-bmi-ruler',
  templateUrl: './bmi-ruler.component.html',
  styleUrls: ['./bmi-ruler.component.scss'],
})
export class BmiRulerComponent implements OnInit {
  product$: Observable<Product> = this.store.select(selectProduct);
  bmiRank = [
    { val: 12, color: '#5ECAEB' },
    { val: 18.5, color: '#5ECAEB' },
    { val: 25, color: '#8BD54C' },
    { val: 30, color: '#FDCD29' },
    { val: 35, color: '#F82746' },
  ];
  bmiElderRank = [
    { val: 12, color: '#5ECAEB' },
    { val: 23, color: '#5ECAEB' },
    { val: 30, color: '#8BD54C' },
    { val: 40, color: '#FDCD29' },
  ];
  goalBmi: number;
  bmi: number;
  rullerRanks: { val: number; color: string }[];
  bmiText = ['תת משקל', 'משקל תקין', 'משקל עודף', 'השמנת יתר'];
  product: Product;
  age: number;
  constructor(
    private store: Store<AppState>,
    private popupHandlerService: PopupHandlerService
  ) {}

  ngOnInit(): void {
    this.product$.subscribe((res) => {
      if (res) {
        this.product = res;
        this.calculateBmi();
        this.calculateGoalBmi();
        this.age = calcAge(this.product.patient.regularUser.birthDate);
        this.rullerRanks = isElder(this.age) ? this.bmiElderRank : this.bmiRank;
      }
    });
  }
  setSmiley() {
    if (this.age <= 65) {
      if (this.bmi == this.goalBmi) {
        return 'party.png';
      } else if (this.bmi < this.bmiRank[0].val) {
        return 'sad.svg';
      } else if (this.bmi <= this.bmiRank[1].val) {
        return 'sad.svg';
      } else if (this.bmi <= this.bmiRank[2].val) {
        return 'happy.svg';
      } else if (this.bmi <= this.bmiRank[3].val) {
        return 'normal.svg';
      } else if (this.bmi <= this.bmiRank[4].val) {
        return 'sad.svg';
      } else {
        return 'normal.svg';
      }
    } else {
      if (this.bmi == this.goalBmi) {
        return 'party.png';
      } else if (this.bmi < this.bmiElderRank[0].val) {
        return 'sad.svg';
      } else if (this.bmi <= this.bmiElderRank[1].val) {
        return 'sad.svg';
      } else if (this.bmi <= this.bmiElderRank[2].val) {
        return 'happy.svg';
      } else if (this.bmi <= this.bmiElderRank[3].val) {
        return 'normal.svg';
      } else {
        return 'normal.svg';
      }
    }
  }
  calcRangeRuller(currItem, prevItem) {
    const min = this.rullerRanks[0].val;
    const max = this.rullerRanks[this.rullerRanks.length - 1].val;
    if (currItem < prevItem) {
      let tempCurrItem = currItem;
      currItem = prevItem;
      prevItem = tempCurrItem;
    }
    const result =
      this.calcPrecentage(max, min, currItem) -
      this.calcPrecentage(max, min, prevItem);
    return result;
  }
  calcPrecentage(max: number, min: number, value: number) {
    const val = ((value - min) / (max - min)) * 100;

    return val < 0 ? 0 : val > 100 ? 100 : val;
  }
  setBmiText() {
    const bmiPoint = this.bmi;
    const rullerLength = this.rullerRanks.length;
    for (let i = 1; i < rullerLength; i++) {
      if (bmiPoint < this.rullerRanks[i].val) {
        return this.bmiText[i - 1];
      }
    }
    return this.bmiText[rullerLength - 2]; // more than max bmi
  }
  setBmiRange(isGoal = false) {
    return this.calcPrecentage(
      this.rullerRanks[this.rullerRanks.length - 1].val,
      this.rullerRanks[0].val,
      isGoal ? this.goalBmi : this.bmi
    );
  }
  getIndicatorColor(isGoal = false) {
    const bmi = isGoal ? this.goalBmi : this.bmi;
    const rullerLength = this.rullerRanks.length;
    for (let i = 1; i < rullerLength; i++) {
      if (bmi < this.rullerRanks[i].val) {
        return this.rullerRanks[i].color;
      }
    }
    return this.rullerRanks[rullerLength - 1].color; // more than max bmi
  }

  calculateBmi() {
    //var { weight } = this.product.patient.regularUser;
    var weight = this.getCurrentWeight();
    var { height } = this.product.patient.regularUser;
    if (height > 3) {
      height = height / 100;
    }
    var bmi = weight / (height * height);

    this.bmi = Math.round(bmi * 10) / 10;
    // this.bmi = 12;
  }
  getCorrectWeight() {
    let height = this.product.patient.regularUser.height;
    height = height / 100;
    const min = getProperBmiRange(this.age).min * (height * height);
    const max = getProperBmiRange(this.age).max * (height * height);
    return `${Math.round(min)}-${Math.round(max)}`;
  }
  calculateGoalBmi() {
    //var { weight } = this.product.patient.regularUser;
    var weight = this.getCurrentGoalWeight();
    var { height } = this.product.patient.regularUser;
    if (height > 3) {
      height = height / 100;
    }
    var bmi = weight / (height * height);

    this.goalBmi = Math.round(bmi * 10) / 10;
    // this.bmi = 12;
  }
  getCurrentGoalWeight() {
    return this.product.patient.goalWeight;
  }
  getCurrentWeight() {
    if (
      !this.product?.patient?.patientWeightHistories ||
      this.product?.patient?.patientWeightHistories.filter((x) => !x.isGoal)
        .length == 0
    ) {
      return this.getStartingWeight();
    }

    return this.product.patient.patientWeightHistories.filter((x) => !x.isGoal)[
      this.product.patient.patientWeightHistories.filter((x) => !x.isGoal)
        .length - 1
    ].value;
  }
  getStartingWeight() {
    return this.product.patient.regularUser.weight;
  }
  openWightGraph() {
    this.popupHandlerService.openWeightGraphDialog();
  }
}
