<cloudflare-stream
  [poster]="getPoster()"
  [controls]="true"
  [src]="getVideoFile()"
  (ended)="onVideoEnded()"
  (pause)="onVideoPause()"
  [autoplay]="true"
  [currentTime]="video.progress"
  #videoPlayer
></cloudflare-stream>
