import { DatePipe } from '@angular/common';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SwiperModule } from 'swiper/angular';
import { BrowserModule } from '@angular/platform-browser';
//material components

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { EmojiModule } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { AppMinimize } from '@ionic-native/app-minimize/ngx';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { File } from '@ionic-native/file/ngx';
import { Platform } from '@ionic/angular';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { CodeInputModule } from 'angular-code-input';
import { SignaturePadModule } from 'angular2-signaturepad-ios-fix';
import { ImageCropperModule } from 'ngx-image-cropper';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
} from 'ngx-perfect-scrollbar';
import { NgxPrintModule } from 'ngx-print';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ErrorInterceptor } from './Auth/error.interceptor';
import { JwtInterceptor } from './Auth/jwt.interceptor';
import { AuthenticationModule } from './authentication/authentication.module';
import { AlgoLinkDialogComponent } from './Components/algo-link-dialog/algo-link-dialog.component';
import { BloodTestResultsNewComponent } from './Components/blood-test-results-new/blood-test-results-new.component';
import { BloodTestsResultsComponent } from './Components/blood-tests-results/blood-tests-results.component';
import { CallRatingComponent } from './Components/call-rating/call-rating.component';
import { ChatWrapperComponent } from './Components/chat-wrapper/chat-wrapper.component';
import { ChatComponent } from './Components/chat/chat.component';
import { CloseServiceRequestComponent } from './Components/close-service-request/close-service-request.component';
import { CurrentWeightPageComponent } from './Components/current-weight-page/current-weight-page.component';
import { DietMenuMealsComponent } from './Components/diet-menu-meals/diet-menu-meals.component';
import { DietMenuComponent } from './Components/diet-menu/diet-menu.component';
import { DnaReportInnerComponent } from './Components/dna-report-inner/dna-report-inner.component';
import { DnaReportComponent } from './Components/dna-report/dna-report.component';
import { ButtonComponent } from './components/dynamic-form-elements/button/button.component';
import { CheckboxComponent } from './components/dynamic-form-elements/checkbox/checkbox.component';
import { DynamicFieldDirective } from './components/dynamic-form-elements/dynamic-field/dynamic-field.directive';
import { DynamicFormComponent } from './components/dynamic-form-elements/dynamic-form/dynamic-form.component';
import { InputComponent } from './components/dynamic-form-elements/input/input.component';
import { RadiobuttonComponent } from './components/dynamic-form-elements/radiobutton/radiobutton.component';
import { SelectComponent } from './components/dynamic-form-elements/select/select.component';
import { EatingDiaryComponent } from './Components/eating-diary/eating-diary.component';
import { BloodTestResultSliderComponent } from './Components/Fragments/blood-test-result-slider/blood-test-result-slider.component';
import { LifestyleProgressBarThumbComponent } from './Components/Fragments/lifestyle-progress-bar-thumb/lifestyle-progress-bar-thumb.component';
import { LifestyleProgressBarComponent } from './Components/Fragments/lifestyle-progress-bar/lifestyle-progress-bar.component';
import { WeightInfoComponent } from './Components/Fragments/weight-info/weight-info.component';
import { WeightRulerComponent } from './Components/Fragments/weight-ruler/weight-ruler.component';
import { GeneticRateComponent } from './Components/genetic-rate/genetic-rate.component';
import { GuidePageComponent } from './Components/guide-page/guide-page.component';
import { HomePageComponent } from './Components/home-page/home-page.component';
import { ImageCropperComponent } from './Components/image-cropper/image-cropper.component';
import { LifeStyleReportNewComponent } from './Components/life-style-report-new/life-style-report-new.component';
import { LifeStyleReportComponent } from './Components/life-style-report/life-style-report.component';
import { LifestyleFormDynamicComponent } from './Components/lifestyle-form-dynamic/lifestyle-form-dynamic.component';
import { LoadingPageComponent } from './Components/loading-page/loading-page.component';
import { LogoutComponent } from './Components/logout/logout.component';
import { MeetingScheduleComponent } from './Components/meeting-schedule/meeting-schedule.component';
import { NavbarMobileComponent } from './Components/navbar-mobile/navbar-mobile.component';
import { PersonalDetailsComponent } from './Components/personal-details/personal-details.component';
import { PersonalProfileComponent } from './Components/personal-profile/personal-profile.component';
import { PostUpgradeDialogComponent } from './Components/post-upgrade-dialog/post-upgrade-dialog.component';
import { PrivacyPolicyComponent } from './Components/privacy-policy/privacy-policy.component';
import { ProfilePageComponent } from './Components/profile-page/profile-page.component';
import { RemoveDnaDataDialogComponent } from './Components/remove-dna-data-dialog/remove-dna-data-dialog.component';
import { ReportRecomendationsComponent } from './Components/report-recomendations/report-recomendations.component';
import { ScanBarcodeComponent } from './Components/scan-barcode/scan-barcode.component';
import { ServiceRequestsComponent } from './Components/service-requests/service-requests.component';
import { StatusBarComponent } from './Components/status-bar/status-bar.component';
import { TermsOfUseComponent } from './Components/terms-of-use/terms-of-use.component';
import { UpdateGoalPageComponent } from './Components/update-goal-page/update-goal-page.component';
import { UpdateUserDataComponent } from './Components/update-user-data/update-user-data.component';
import { UpgradePreviewDialogComponent } from './Components/upgrade-preview-dialog/upgrade-preview-dialog.component';
import { UpgradeComponent } from './Components/upgrade/upgrade.component';
import { VodComponent } from './Components/vod/vod.component';
import { WeightGoalGraphComponent } from './Components/weight-goal-graph/weight-goal-graph.component';
import { AutocompleteAddressComponent } from './Fragments/autocomplete-address/autocomplete-address.component';
import { BloodTestQuestionComponent } from './Fragments/blood-test-question/blood-test-question.component';
import { BloodtestDesktopComponent } from './Fragments/bloodtest-desktop/bloodtest-desktop.component';
import { BloodtestMobileComponent } from './Fragments/bloodtest-mobile/bloodtest-mobile.component';
import { CanvasDrawingThumbComponent } from './Fragments/canvas-drawing-thumb/canvas-drawing-thumb.component';
import { CanvasWhiteboardThumbComponent } from './Fragments/canvas-whiteboard-thumb/canvas-whiteboard-thumb.component';
//import { DnaReportPrintComponent } from './Components/dna-report-print/dna-report-print.component';
import { ConfirmDialogComponent } from './Fragments/confirm-dialog/confirm-dialog.component';
import { EnterWeightDialogComponent } from './Fragments/enter-weight-dialog/enter-weight-dialog.component';
import { HasNotBloodTestComponent } from './Fragments/has-not-blood-test/has-not-blood-test.component';
import { LeadConfirmChangeDialogComponent } from './Fragments/lead-confirm-change-dialog/lead-confirm-change-dialog.component';
import { LifestyleQuestionNodeComponent } from './Fragments/lifestyle-question-node/lifestyle-question-node.component';
import { ResultsInfoComponent } from './Fragments/results-info/results-info.component';
import { SchedualCalendarHeaderComponent } from './Fragments/schedual-calendar-header/schedual-calendar-header.component';
import { SignatureThumbComponent } from './Fragments/signature-thumb/signature-thumb.component';
import { SnapSliderComponent } from './Fragments/snap-slider/snap-slider.component';
import { TendenyThumbComponent } from './Fragments/tendeny-thumb/tendeny-thumb.component';
import { TestActivityThumbComponent } from './Fragments/test-activity-thumb/test-activity-thumb.component';
import { TopBarMobileComponent } from './Fragments/top-bar-mobile/top-bar-mobile.component';
import { DnaReportEffects } from './Redux/Effects/dna-report.effects';
import { LifeStyleEffects } from './Redux/Effects/life-style.effects';
import { LifestyleQuestionEffects } from './Redux/Effects/lifestyle-question.effects';
import { ProductEffects } from './Redux/Effects/product.effects';
import { ServiceRequestEffects } from './Redux/Effects/service-request.effects';
import { UserEffects } from './Redux/Effects/user.effects';
import { clearState, reducers } from './Redux/reducers';
import { CustomSerializer } from './RouterSerializer ';
import { CanDeactivateGuard } from './Services/can-deactivate-guard.service';
import { FirebaseAnonimousAuthService } from './Services/firebase-anonimous-auth.service';
import { WeightHistoryDialogComponent } from './weight-history-dialog/weight-history-dialog.component';
import { MaterialModule } from './material/material.module';
import { CloudflareStreamModule } from '@cloudflare/stream-angular';
import { SideNavComponent } from './Components/side-nav/side-nav.component';
import { ChangeLangComponent } from './Fragments/change-lang/change-lang.component';
import { UpcomingEventsComponent } from './Components/upcoming-events/upcoming-events.component';
import { RecomendationSliderComponent } from './Components/recomendation-slider/recomendation-slider.component';
import { HomeAlertsComponent } from './Components/home-alerts/home-alerts.component';
import { WeightGraphDialogComponent } from './Fragments/weight-graph-dialog/weight-graph-dialog.component';
import { VideoDialogComponent } from './Fragments/video-dialog/video-dialog.component';
import { VideoItemComponent } from './Fragments/video-item/video-item.component';
import { HomeVodComponent } from './Components/home-vod/home-vod.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};
import { CircleTimerModule } from '@flxng/circle-timer';
import { BmiRulerComponent } from './Components/bmi-ruler/bmi-ruler.component';
import { MenuHtmlComponent } from './Components/menu-html/menu-html.component';
import { LifeStyleReportAppComponent } from './life-style-report-app/life-style-report-app.component';
import { VideoPlayerComponent } from './Components/video-player/video-player.component';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

const maskConfigFunction: () => Partial<IConfig> = () => {
  return {
    validation: false,
  };
};
/* const firebaseConfig = {
  apiKey: 'AIzaSyDdIm8PGQJS45Sr7_hYAUDFrJrNvQ8HSZk',
  authDomain: 'mygeneschat.firebaseapp.com',
  databaseURL: 'https://mygeneschat.firebaseio.com',
  projectId: 'mygeneschat',
  storageBucket: 'mygeneschat.appspot.com',
  messagingSenderId: '1087373424417',
  appId: '1:1087373424417:web:379dcd7919a9584a243060',
  measurementId: 'G-VFY8K5FF83',
}; */
@NgModule({
  declarations: [
    AppComponent,
    //LoginPageComponent,
    //RegisterPageComponent,
    PersonalDetailsComponent,
    HomePageComponent,
    ScanBarcodeComponent,
    StatusBarComponent,
    LogoutComponent,
    GuidePageComponent,
    DnaReportComponent,
    DietMenuComponent,
    EatingDiaryComponent,
    ServiceRequestsComponent,
    LifeStyleReportComponent,
    BloodTestsResultsComponent,
    PersonalProfileComponent,
    LoadingPageComponent,
    BloodTestQuestionComponent,
    DnaReportInnerComponent,
    TendenyThumbComponent,
    TestActivityThumbComponent,
    EnterWeightDialogComponent,
    LifestyleFormDynamicComponent,
    InputComponent,
    ButtonComponent,
    SelectComponent,
    RadiobuttonComponent,
    CheckboxComponent,
    DynamicFieldDirective,
    DynamicFormComponent,
    WeightHistoryDialogComponent,
    //DnaReportPrintComponent,
    ConfirmDialogComponent,
    ChatComponent,
    CloseServiceRequestComponent,
    TermsOfUseComponent,
    PrivacyPolicyComponent,
    UpgradeComponent,
    UpgradePreviewDialogComponent,
    PostUpgradeDialogComponent,
    SignatureThumbComponent,
    CanvasWhiteboardThumbComponent,
    CanvasDrawingThumbComponent,
    CallRatingComponent,
    ChatWrapperComponent,
    DietMenuMealsComponent,
    GeneticRateComponent,
    LifeStyleReportNewComponent,
    LifestyleQuestionNodeComponent,
    ReportRecomendationsComponent,
    SnapSliderComponent,
    LifestyleProgressBarComponent,
    LifestyleProgressBarThumbComponent,
    AlgoLinkDialogComponent,
    HasNotBloodTestComponent,
    BloodTestResultsNewComponent,
    LeadConfirmChangeDialogComponent,
    BloodTestResultSliderComponent,
    BloodtestDesktopComponent,
    BloodtestMobileComponent,
    ProfilePageComponent,
    WeightGoalGraphComponent,
    ImageCropperComponent,
    TopBarMobileComponent,
    UpdateUserDataComponent,
    NavbarMobileComponent,
    WeightRulerComponent,
    WeightInfoComponent,
    UpdateGoalPageComponent,
    CurrentWeightPageComponent,
    RemoveDnaDataDialogComponent,
    AutocompleteAddressComponent,
    ResultsInfoComponent,
    VodComponent,
    MeetingScheduleComponent,
    SchedualCalendarHeaderComponent,
    SideNavComponent,
    ChangeLangComponent,
    UpcomingEventsComponent,
    RecomendationSliderComponent,
    HomeAlertsComponent,
    WeightGraphDialogComponent,
    VideoDialogComponent,
    VideoItemComponent,
    HomeVodComponent,
    BmiRulerComponent,
    MenuHtmlComponent,
    LifeStyleReportAppComponent,
    VideoPlayerComponent,
  ],
  imports: [
    NgxSkeletonLoaderModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(maskConfigFunction),
    NgxChartsModule,
    NgxPrintModule,
    SwiperModule,
    StoreModule.forRoot(reducers, {
      metaReducers: [clearState],
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: true,
        strictActionSerializability: false,
        strictActionWithinNgZone: true,
        strictActionTypeUniqueness: true,
      },
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),

    EffectsModule.forRoot([
      UserEffects,
      ProductEffects,
      LifeStyleEffects,
      DnaReportEffects,
      ServiceRequestEffects,
      LifestyleQuestionEffects,
    ]),
    StoreRouterConnectingModule.forRoot({
      serializer: CustomSerializer,
      stateKey: 'router',
    }),
    //EffectsModule.forFeature([ServiceRequestEffects, LifestyleQuestionEffects]),
    //EffectsModule.forFeature([LifeStyleEffects]),
    /*
    StoreModule.forFeature(
      fromAppStore.appStoreFeatureKey,
      fromAppStore.reducers,
      { metaReducers: fromAppStore.metaReducers }
    ),
   
    EffectsModule.forFeature([UserEffects, ProductEffects]), */
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,
    TranslateModule.forRoot({
      defaultLanguage: 'he',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    SignaturePadModule,
    PerfectScrollbarModule,
    PickerModule,
    EmojiModule,

    // CanvasWhiteboardModule,
    ImageCropperModule,
    CodeInputModule,
    AuthenticationModule,
    MaterialModule,
    CloudflareStreamModule,
    CircleTimerModule,
  ],
  providers: [
    CanDeactivateGuard,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },

    DatePipe,
    BarcodeScanner,
    Platform,
    File,
    FileOpener,
    AppMinimize,
    BackgroundMode,
    FirebaseAnonimousAuthService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
