import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './Auth/auth.guard';
import { BloodTestResultsNewComponent } from './Components/blood-test-results-new/blood-test-results-new.component';
import { BloodTestsResultsComponent } from './Components/blood-tests-results/blood-tests-results.component';
import { CallRatingComponent } from './Components/call-rating/call-rating.component';
import { ChatWrapperComponent } from './Components/chat-wrapper/chat-wrapper.component';
import { CurrentWeightPageComponent } from './Components/current-weight-page/current-weight-page.component';
import { DietMenuMealsComponent } from './Components/diet-menu-meals/diet-menu-meals.component';
import { DietMenuComponent } from './Components/diet-menu/diet-menu.component';
import { DnaReportInnerComponent } from './Components/dna-report-inner/dna-report-inner.component';
import { DnaReportComponent } from './Components/dna-report/dna-report.component';
import { EatingDiaryComponent } from './Components/eating-diary/eating-diary.component';
import { GuidePageComponent } from './Components/guide-page/guide-page.component';
import { HomePageComponent } from './Components/home-page/home-page.component';
import { LifeStyleReportNewComponent } from './Components/life-style-report-new/life-style-report-new.component';
import { LifeStyleReportComponent } from './Components/life-style-report/life-style-report.component';
import { LifestyleFormDynamicComponent } from './Components/lifestyle-form-dynamic/lifestyle-form-dynamic.component';
import { LoginPageComponent } from './Components/login-page/login-page.component';
import { LogoutComponent } from './Components/logout/logout.component';
import { MeetingScheduleComponent } from './Components/meeting-schedule/meeting-schedule.component';
import { PersonalDetailsComponent } from './Components/personal-details/personal-details.component';
import { PersonalProfileComponent } from './Components/personal-profile/personal-profile.component';
import { RegisterPageComponent } from './Components/register-page/register-page.component';
import { ReportRecomendationsComponent } from './Components/report-recomendations/report-recomendations.component';
import { ScanBarcodeComponent } from './Components/scan-barcode/scan-barcode.component';
import { ServiceRequestsComponent } from './Components/service-requests/service-requests.component';
import { TermsOfUseComponent } from './Components/terms-of-use/terms-of-use.component';
import { UpdateGoalPageComponent } from './Components/update-goal-page/update-goal-page.component';
import { UpdateUserDataComponent } from './Components/update-user-data/update-user-data.component';
import { UpgradeComponent } from './Components/upgrade/upgrade.component';
import { VodComponent } from './Components/vod/vod.component';
import { WeightGoalGraphComponent } from './Components/weight-goal-graph/weight-goal-graph.component';
import { LifeStyleReportAppComponent } from './life-style-report-app/life-style-report-app.component';
import { CanDeactivateGuard } from './Services/can-deactivate-guard.service';

const routes: Routes = [
  //{ path: '', redirectTo: 'home', pathMatch: 'full' },

  { path: 'home', component: HomePageComponent, canActivate: [AuthGuard] },
  { path: 'guide', component: GuidePageComponent },
  {
    path: '',
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (f) => f.AuthenticationModule
      ),
  },
  //{ path: 'register', component: RegisterPageComponent },
  //{ path: 'forgot-password', component: RegisterPageComponent },
  { path: 'logout', component: LogoutComponent },
  {
    path: 'personal-details',
    component: PersonalDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'terms-of-use',
    component: TermsOfUseComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'scan-barcode',
    component: ScanBarcodeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'eating-diary',
    component: EatingDiaryComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'diet-menu',
    component: DietMenuComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'diet-menu-meals',
    component: DietMenuMealsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'example-diet-menu',
    component: DietMenuComponent,
  },
  {
    path: 'example-diet-menu-meals',
    component: DietMenuMealsComponent,
  },
  {
    path: 'service-requests',
    component: ServiceRequestsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'bloodtests-results',
    component: BloodTestsResultsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'bloodtests-results-new',
    component: BloodTestResultsNewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'suggar-results',
    component: BloodTestsResultsComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'lifestyle-report-old',
    component: LifeStyleReportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'lifestyle-report',
    component: LifestyleFormDynamicComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'lifestyle-report-new',
    component: LifeStyleReportNewComponent,
    canActivate: [AuthGuard],
    //canDeactivate: [CanDeactivateGuard],
  },

  {
    path: 'dna-report',
    component: DnaReportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'dna-report-inner',
    component: DnaReportInnerComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'example-report',
    component: DnaReportComponent,
  },
  {
    path: 'example-report-inner',
    component: DnaReportInnerComponent,
  },
  {
    path: 'profile',
    component: PersonalProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'update-user-data',
    component: UpdateUserDataComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'upgrade',
    component: UpgradeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'report-recomendations',
    component: ReportRecomendationsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'example-report-recomendations',
    component: ReportRecomendationsComponent,
  },
  {
    path: 'weight-graph',
    component: WeightGoalGraphComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'update-goal',
    component: UpdateGoalPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'current-weight',
    component: CurrentWeightPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'vod',
    component: VodComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'chat',
    component: ChatWrapperComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'meeting-schedule',
    component: MeetingScheduleComponent,
    canActivate: [AuthGuard],
  },
  { path: 'callreview', component: CallRatingComponent },
  {
    path: 'lifestyle-app',
    component: LifeStyleReportAppComponent,
  },
  { path: '**', redirectTo: 'home' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
