<div class="page-title">שדרוג לבדיקה מורחבת</div>
<div style="position: relative" class="mt-4">
  <div *ngIf="step == 2 && isLoading" class="loadingOverlay">
    <mat-spinner diameter="50"></mat-spinner>
  </div>
  <!--   <div class="container-back-btn">
    <button (click)="backBtn(step)" mat-button>< חזרה</button>
  </div> -->
  <div *ngIf="step == 1" class="container-before-checkout">
    <img
      src="../../../assets/smiley/happy.svg"
      style="max-width: 100%; display: block; margin: 0 auto"
      alt=""
    />

    <div class="title">שמחים שאתה כאן</div>
    <div class="subtitle">
      תוכל לשדרג לבדיקה המורחבת שלנו ולקבל את התוצאות בתוך 48 שעות לכל היותר
      (בלי צורך בבדיקה נוספת)
    </div>
    <div class="features">
      <div class="features__title">אלו הדברים שתקבל בבדיקה המורחבת שלנו:</div>

      <div class="features__item">
        <div class="features__item__img">
          <img src="../../../assets/upgrade-icons/phone.svg" />
        </div>
        <div class="features__item__text">
          שיחה של 45 דקות עם תזונאית קלינית
        </div>
      </div>
      <div class="features__item">
        <div class="features__item__img">
          <img src="../../../assets/upgrade-icons/menu.svg" />
        </div>
        <div class="features__item__text">בניית תפריט אישי ועקרונות תזונה</div>
      </div>
      <div class="features__item">
        <div class="features__item__img">
          <img src="../../../assets/upgrade-icons/chart.svg" />
        </div>
        <div class="features__item__text">
          שקלול הממצאים עם בדיקות דם ושאלון אורח חיים.
        </div>
      </div>

      <div class="features__title mt-4">
        בנוסף תוכל לגלות על עצמך את הממצאים הבאים:
      </div>
      <div class="features__item">
        <div class="features__item__img">
          <img src="../../../assets/upgrade-icons/bone.svg" alt="" />
        </div>
        <div class="features__item__text">רגישות לבעיות בצפיפות עצם</div>
      </div>
      <div class="features__item">
        <div class="features__item__img">
          <img src="../../../assets/upgrade-icons/tast.svg" alt="" />
        </div>
        <div class="features__item__text">
          רגישות לטעמים (מתוק, מלוח, מר אומאמי)
        </div>
      </div>
      <div class="features__item">
        <div class="features__item__img">
          <img src="../../../assets/upgrade-icons/alcohol.svg" alt="" />
        </div>
        <div class="features__item__text">אלכוהול נטיה לצריכת יתר</div>
      </div>
      <div class="features__item">
        <div class="features__item__img">
          <img src="../../../assets/upgrade-icons/clock.svg" alt="" />
        </div>
        <div class="features__item__text">
          שעון ביולוגי(זמנים מיטביים לאכילה)
        </div>
      </div>
      <div class="features__item">
        <div class="features__item__img">
          <img src="../../../assets/upgrade-icons/smoke.svg" alt="" />
        </div>
        <div class="features__item__text">עישון נטייה להתמכרות</div>
      </div>
      <div class="features__item">
        <div class="features__item__img">
          <img src="../../../assets/upgrade-icons/sport.svg" alt="" />
        </div>
        <div class="features__item__text">
          המלצות לספורט חיטוב ומניעת פציעות
        </div>
      </div>
      <div class="features__item">
        <div class="features__item__img">
          <img src="../../../assets/upgrade-icons/alcohol-2.svg" alt="" />
        </div>
        <div class="features__item__text">מטבוליזם של אלכוהול</div>
      </div>
    </div>

    <mat-card class="cta">
      <div class="cta__text">
        <div class="price">₪750</div>
        <div class="desc">למשדרגים באתר בלבד</div>
        <div class="discount">במקום <span>₪950</span></div>
      </div>
      <button
        mat-button
        class="btn btn--fill btn--big"
        (click)="incStep()"
        mat-button
      >
        שדרג עכשיו!
      </button>
    </mat-card>
  </div>
  <div *ngIf="step == 2">
    <div class="container-checkout">
      <div class="mt-4"></div>
      <div class="title">בחרת לשדרג את הבדיקה הבסיסית לבדיקה מורחבת</div>
      <div class="subtitle">
        <div class="subtitle__price">₪750</div>
        <div class="subtitle__text">למשדרגים באתר בלבד</div>
        <div class="subtitle__discount">במקום <span>₪950</span></div>
      </div>
      <form class="form" (ngSubmit)="onSubmit()" [formGroup]="upgradeForm">
        <div class="form__title">פרטי אשראי</div>
        <mat-form-field class="upgrade" style="width: 100%" appearance="fill">
          <mat-label>שם בעל/ת הכרטיס</mat-label>
          <input placeholder="שם מלא" formControlName="fullName" matInput />
        </mat-form-field>
        <mat-form-field class="upgrade" style="width: 100%" appearance="fill">
          <mat-label>מספר הכרטיס</mat-label>
          <input
            style="direction: ltr"
            mask="0000 0000 0000 0000"
            placeholder="מספר כרטיס"
            formControlName="cardNumber"
            matInput
          />
        </mat-form-field>
        <div class="row">
          <div class="expierDate">
            <mat-form-field class="upgrade" appearance="fill">
              <mat-label>תאריך תפוגה</mat-label>
              <input
                mask="00/00"
                placeholder="00/00"
                formControlName="expierDate"
                matInput
              />
            </mat-form-field>
          </div>
          <div class="cvv">
            <mat-form-field class="upgrade" appearance="fill">
              <mat-label>CVV</mat-label>
              <input
                mask="0000"
                placeholder="123"
                formControlName="cvv"
                matInput
              />
            </mat-form-field>
          </div>
        </div>
        <mat-form-field class="upgrade" style="width: 100%" appearance="fill">
          <mat-label>תעודת זהות</mat-label>
          <input
            placeholder="ת.ז"
            formControlName="socialSecurityNum"
            matInput
          />
        </mat-form-field>
        <mat-form-field class="upgrade" style="width: 100%" appearance="fill">
          <mat-label>תשלומים</mat-label>
          <mat-select formControlName="numPayments">
            <mat-option value="1">1</mat-option>
            <mat-option value="2">2</mat-option>
            <mat-option value="3">3</mat-option>
            <mat-option value="4">4</mat-option>
          </mat-select>
        </mat-form-field>
        <div>
          <button class="btn btn--fill btn--big mt-3" mat-button>
            לחץ כאן עכשיו לרכישה מאובטחת
          </button>
          <div
            style="
              color: red;
              text-align: center;
              font-size: 16px;
              margin-top: 2rem;
              font-weight: 600;
            "
            *ngIf="isBankErrorMsg"
            [innerHTML]="tranzillaErrorMsg"
          ></div>

          <img
            src="../../../assets/secure_checkout.png"
            alt=""
            style="width: 100%; max-width: 100%; height: auto; margin-top: 4rem"
          />
        </div>
      </form>
    </div>
  </div>
  <div *ngIf="step == 3" class="container-after-checkout">
    <img src="../../../assets/like.svg" style="max-width: 100%" alt="" />
    <div class="title">
      כל הכבוד!
      <div>שדרגת בהצלחה לבדיקה מורחבת!</div>
    </div>
    <div style="color: #1b3f4e">
      חשבונית נשלחה אליך למייל. <br />
      בתוך 48 שעות תוכל לצפות בתוצאות הנוספות.
    </div>
    <div class="text">
      <div class="title">לבינתיים...</div>
      <div class="desc">
        אנו ממליצים לך למלא שאלון אורח חיים ובדיקות דם עדכניות לקראת השיחה עם
        הדיאטנית הקלינית.
        <div class="regular">
          לאחר שתמלא שאלון אורח חיים ובדיקות דם אנחנו ניצור איתך קשר לתיאום שיחה
          עם דיאטנית
        </div>
      </div>
    </div>
    <button
      class="mt-4 btn btn--fill btn--big"
      (click)="goToLifestyleReport()"
      mat-button
    >
      לחץ כאן למילוי שאלון אורח חיים ובדיקות דם
    </button>
  </div>
</div>
