<!-- <div class="page-title">ראשי</div>
 -->
<div #homeWrapper class="home-wraper" *ngIf="product">
  <div class="greeting" *ngIf="patient$ | async">
    <span class="greeting__name">
      {{ (patient$ | async).regularUser.firstName }}</span
    >, טוב לראותך!
  </div>
  <div class="info">
    <div class="card-title">עדכון משקלים</div>

    <app-weight-info
      [patient]="patient$ | async"
      style="width: 100%"
      (restartGoalSession)="onRestartGoalSession()"
    ></app-weight-info>
  </div>
  <div class="bmi">
    <div class="card-title">ה BMI שלי</div>
    <mat-card>
      <app-bmi-ruler></app-bmi-ruler>
    </mat-card>
  </div>
  <div class="video">
    <div class="card-title">הדרכה חשובה:</div>
    <div class="card-sub-title">הבנת תוצאות הבדיקה הגנטית שלך</div>
    <mat-card (click)="goToVod()" class="home-video"> </mat-card>
  </div>
  <div class="rec">
    <div class="card-title">
      <mat-icon class="tooltip-trigger">info_outline</mat-icon>
      קלקולטור
      <mat-card class="tooltip">
        קלקולטור החדשני של MyGenes משקלל את כלל נתוני הבריאות שלך יחד עם תוצאות
        הדוח הגנטי ההמלצות מבוססות על הגנטיקה שלך, בדיקות הדם ו שאלון אורח החיים
        שמילאת
      </mat-card>
    </div>
    <mat-card>
      <app-recomendation-slider></app-recomendation-slider>
    </mat-card>
  </div>
  <!-- TODO: next sprint-->
  <mat-card class="events" *ngIf="false">
    <div class="card-title">אירועים קרובים</div>
    <app-upcoming-events></app-upcoming-events>
  </mat-card>
  <!-- TODO: next sprint-->
  <div class="alerts" *ngIf="alertsCount > 0">
    <div
      [matBadgeHidden]="alertsCount == 0"
      [matBadge]="alertsCount"
      matBadgeColor="warn"
      matBadgeOverlap="false"
      matBadgeSize="small"
      class="card-title"
    >
      הודעות חשובות!
    </div>
    <app-home-alerts (alertsCount)="getAlertsCount($event)"></app-home-alerts>
  </div>
  <!-- TODO: next sprint -->
  <div class="vod" *ngIf="false">
    <div class="card-title">תכני VOD המותאמים אישית עבורך</div>
    <app-home-vod></app-home-vod>
  </div>
  <div class="app-store">
    <div class="app-store__text">
      <mat-icon class="tooltip-trigger">info_outline</mat-icon>
      לחוויה מיטבית מומלץ לעבור לאפליקציה.
      <mat-card class="tooltip">
        בממשק זה הפונקציונליות מוגבלת מאד. כדי להנות מכל מה שיש לנו להציע יש
        להתחבר באמצעות האפליקציה.
      </mat-card>
    </div>
    <div class="d-flex">
      <img src="../../../assets/play-store.svg" alt="" />
      &nbsp;&nbsp;&nbsp;&nbsp;
      <img src="../../../assets/app-store.svg" alt="" />
    </div>
  </div>
</div>
<!-- <div *ngIf="product" class="home-wraper">
  <div *ngIf="inLabStatus()" class="arrive-to-lab-status">
    <div class="arrive-to-lab-status__title">הבדיקה שלך נמצאת במעבדה</div>
    <img src="../../../assets/arrive-to-lab-status.svg" alt="" />
  </div>
  <ng-container *ngIf="isNutroPlus() == true">
    <div
      [@bounceInLeft]="{
        value: animationState,
        params: { duration: '1000', delay: '0', translate: '3000px' }
      }"
      style="
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 3rem 0 4rem;
      "
    >
      <mat-card
        class="mission-card"
        (click)="goToLifestyleReport()"
        *ngIf="!isLifeStyleReportComplete()"
      >
        <div class="mission-card__text">
          <div class="mission-card__text__image">
            <mat-icon> help_outline </mat-icon>
          </div>
          <div>
            <div class="mission-card__text__title">שאלון אורח חיים</div>
            <div class="mission-card__text__desc">ורם איפסום דולור סיט אמט</div>
          </div>
        </div>
        <mat-icon class="mission-card__missing-icon">error_outline</mat-icon>
      </mat-card>
      <mat-card
        class="mission-card"
        (click)="goToBloodTestReport()"
        *ngIf="!product.bloodTestForBulletsFilled"
      >
        <div class="mission-card__text">
          <div class="mission-card__text__image">
            <img src="../../../assets/tube.svg" alt="" />
          </div>
          <div>
            <div class="mission-card__text__title">תוצאות בדיקת דם</div>
            <div class="mission-card__text__desc">ורם איפסום דולור סיט אמט</div>
          </div>
        </div>
        <mat-icon class="mission-card__missing-icon">error_outline</mat-icon>
      </mat-card>
    </div>
  </ng-container>
  <section class="bmi">
   
  </section>
  <app-weight-goal-graph style="width: 100%"></app-weight-goal-graph>
  <app-weight-info
    [patient]="patient$ | async"
    style="width: 100%"
    (restartGoalSession)="onRestartGoalSession()"
  ></app-weight-info>

  <div class="pa-level">
    <div class="pa-level__graph">
      <div
        *ngFor="let item of [1, 2, 3, 4]; let index = index"
        class="pa-level__graph__item pa-level__graph__item--{{ index + 1 }}"
        [class.filled]="product.sportActivityData.activityLevel >= index + 1"
      ></div>
    </div>
    <div class="pa-level__title">
      <span>רמת הפעילות שלך: </span>
      <strong>{{ userActivity.level | translate }}</strong>
    </div>
    <div class="pa-level__desc">
      <div>
        הנך עושה
        <strong>{{ userActivity.time | number: "1.0-0" }} שעות</strong> פעילות
        בשבוע
        <ng-container *ngIf="userActivity.sprotsList"
          >ומשלב
          <div>{{ userActivity.sprotsList }}</div></ng-container
        >
      </div>
    </div>
    <div class="pa-level__update">
      <button
        (click)="goToActivityLifestyle()"
        mat-button
        class="btn btn--underline btn--primary btn--center"
      >
        עדכן רמת פעילות
      </button>
    </div>
  </div>

  <div style="height: 50px; width: 100%" *ngIf="isMobile()"></div>
</div> -->
