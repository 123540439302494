import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { bounceInLeftAnimation } from 'angular-animations';
//import { NgAnimateScrollService } from 'ng-animate-scroll/lib/ng-animate-scroll.service';
import { NgAnimateScrollService } from 'ng-animate-scroll';
import { Observable } from 'rxjs';
import { BloodTestType } from 'src/app/data/BloodTestType';
import { Patient } from 'src/app/data/Patients';
import { Product } from 'src/app/data/Product';
import { Status } from 'src/app/data/Status';
import { VideoForSite } from 'src/app/data/VideoForSite';
import {
  FileUploadTypeEnum,
  ProductStatusEnum,
  ProductTypeEnum,
} from 'src/app/Enum/StatusesEnum';
import { LoadProduct } from 'src/app/Redux/Actions/product.actions';
import { AppState } from 'src/app/Redux/reducers';
import {
  selectPatient,
  selectProduct,
} from 'src/app/Redux/selectors/product.selectors';
import { selectShowPdfBullets } from 'src/app/Redux/selectors/user.selectors';
import { BloodTestTypesService } from 'src/app/Services/blood-test-types.service';
import { ExelDownloadService } from 'src/app/Services/exel-download.service';
import { FirebaseAnonimousAuthService } from 'src/app/Services/firebase-anonimous-auth.service';
import { PatientsService } from 'src/app/Services/patients.service';
import { PopupHandlerService } from 'src/app/Services/popup-handler.service';
import { ProductService } from 'src/app/Services/product.service';
import { RegularUsersService } from 'src/app/Services/regular-users.service';
import { VideosService } from 'src/app/Services/videos.service';
import { PALevel } from 'src/app/Utils/userHelper';
import { environment } from 'src/environments/environment';
import { isMobileAndTable } from '../../Utils/userAgent';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
  animations: [bounceInLeftAnimation()],
})
export class HomePageComponent implements OnInit {
  showUpdateWeightBtn: boolean = false;

  patient$: Observable<Patient> = this.store.select(selectPatient);
  patientId: number;
  product: Product;
  productStatus: Status;
  loaded: boolean = false;
  fakeStatusId: any = ProductStatusEnum.EnteredBarcode;
  weightHistory: number;

  bloodTestTypes: BloodTestType[];
  weightEdit: boolean = false;
  disableServiceRequest: boolean = environment.disableServiceRequest;
  @ViewChild('weightInput') weightInput: ElementRef;
  @ViewChild('homeWrapper') homeWrapper: ElementRef;

  animation = 'bounceInLeft';
  animationState = false;
  animationWithState = false;
  hueBtnState = false;
  weightHistoryError: boolean = false;
  isFireLogged: boolean = false;

  product$: Observable<Product> = this.store.select(selectProduct);
  showPdfBullets$: Observable<boolean> =
    this.store.select(selectShowPdfBullets);

  bmiChildRank;

  menuState: boolean;
  userActivity: { level: string; time: number; sprotsList: string } = {
    level: '',
    time: null,
    sprotsList: '',
  };
  fakeLevel: number;
  activityGraphArray: any[];
  alertsCount: number;
  videoList: VideoForSite[];
  selectedVideo: VideoForSite;

  constructor(
    private regularUsersService: RegularUsersService,
    private productService: ProductService,
    private bloodTestTypesService: BloodTestTypesService,
    private router: Router,
    private route: ActivatedRoute,
    private popupHandlerService: PopupHandlerService,
    private patientsService: PatientsService,
    private exelDownloadService: ExelDownloadService,
    private animateScrollService: NgAnimateScrollService,
    private datePipe: DatePipe,
    private anonAuthService: FirebaseAnonimousAuthService,
    private store: Store<AppState>,
    public translate: TranslateService,
    private videosService: VideosService
  ) {
    /*this.popupHandlerService.weightResult.subscribe((weight) => {
      this.weightHistory = weight;
      this.patientsService
        .updateWeight(this.patientId, weight)
        .subscribe((res) => {});
    });*/

    this.product$.subscribe((res) => {
      if (res) {
        this.product = res;
        //this.getBloodTestTypes();

        //this.fakeLevel = 2;

        this.userActivity.level = PALevel(
          this.product?.sportActivityData?.activityLevel
        );
        this.activityGraphArray = Array(this.fakeLevel);
        this.userActivity.sprotsList = this.product.sportActivityData.sportList
          ?.map((x) => translate.instant(x))
          .join(', ');
        this.userActivity.time = this.product.sportActivityData.activityTime;
      }
    });

    regularUsersService.currentUser.subscribe((user) => {
      if (user === undefined) return;
      if (!user) {
        // this.router.navigate(['login']);
        return;
      }

      this.patientId = user.patient.patientId;
      // this.getProduct();
      this.getVideos();
    });
  }

  goToVod() {
    this.router.navigate(['/vod']);
  }
  getVideos() {
    this.videosService.getAll().subscribe((data) => {
      this.videoList = data;
      this.selectedVideo = this.videoList[0];
      //this.filteredVideos = [...this.videoList];
    });
  }

  getAlertsCount(e: number) {
    this.alertsCount = e;
  }

  animate() {
    this.animationState = false;
    setTimeout(() => {
      this.animationState = true;
      this.animationWithState = !this.animationWithState;
    }, 1);
  }
  getProduct() {
    this.productService.getByPatientId(this.patientId).subscribe((product) => {
      this.product = product as Product;
      //this.calculateBmi();
      //this.weightHistory = this.getCurrentWeight();
      this.productStatus = this.product.status;

      /* if (!this.product.patient.filledDetails)
        this.router.navigate(['personal-details']);
      else */
      if (
        environment.phase >= 2 &&
        (product.statusId == ProductStatusEnum.Completed ||
          product.statusId == ProductStatusEnum.ArrivedToLab ||
          product.statusId == ProductStatusEnum.ArrivedToOffice ||
          product.statusId == ProductStatusEnum.LaboratoryProcess ||
          product.statusId == ProductStatusEnum.RetestOnLab ||
          product.statusId == ProductStatusEnum.WaitingToPickUp)
      ) {
        this.loaded = true;
      }
    });
  }
  /*
  insertBarcode() {
    this.product.barCode = 'dfgdfgfdgdfg';
    this.productService.insertBarcode(this.product).subscribe((res) => {});
  }
  orderTubeDelivery() {
    this.productService.orderDelivery(this.product).subscribe((res) => {});
  }
*/
  ngOnInit(): void {
    this.anonAuthService.isFireLogged$.subscribe((data) => {
      if (!data) return;
      this.isFireLogged = data;
    });
    this.route.queryParams.subscribe((param) => {
      if (param.unlock) {
        setTimeout(() => {
          //console.log('scroll!!!');
          // this.animateScrollService.scrollToElement('scrollToMe', 1000);
          window.scroll(400, 0);
          document.body.scrollTop = 400;
          setTimeout(() => {
            this.animate();
          }, 500);
        }, 6000);
      }
    });

    /*
    setTimeout(() => {
      animateScrollTo(1500).then(() => {
        setTimeout(() => {
          this.animate();
        }, 400);
      });
    }, 100);
    */
    //this.calculateBmi();
  }
  getBloodTestTypes() {
    this.bloodTestTypesService.getAll().subscribe((data) => {
      this.bloodTestTypes = data;
    });
  }
  inLabStatus() {
    return (
      this.product.statusId == ProductStatusEnum.ArrivedToLab ||
      this.product.statusId == ProductStatusEnum.ArrivedToOffice ||
      this.product.statusId == ProductStatusEnum.LaboratoryProcess ||
      this.product.statusId == ProductStatusEnum.RetestOnLab
    );
  }
  linkToMenuAsset(inx) {
    if (this.getProductStatus() == ProductStatusEnum.Completed) {
      return `../../../assets/icon-desktop_${inx}.svg`;
    }
    return `../../../assets/icon-desktop_${inx}_disabledA.svg`;
  }
  ifTestCompleted() {
    if (this.getProductStatus() == ProductStatusEnum.Completed) {
      return false;
    }
    return true;
  }
  getStartingDate() {
    if (
      this.product.patient.patientWeightHistories &&
      this.product.patient.patientWeightHistories.filter((x) => !x.isGoal)
        .length > 0
    )
      return this.datePipe.transform(
        this.product.patient.patientWeightHistories.filter((x) => !x.isGoal)[0]
          .dateUpdated,
        'dd.MM.yyyy'
      );
    else {
      return this.datePipe.transform(
        this.product.patient.regularUser.createdDate,
        'dd.MM.yyyy'
      );
    }
  }
  doLogout() {
    this.regularUsersService.logout();
  }
  goToServiceRequests() {
    this.router.navigate(['service-requests']);
  }
  goToPersonalProfile() {
    this.router.navigate(['profile']);
  }
  goToEatingDiary() {
    this.router.navigate(['eating-diary']);
  }
  goToDietMenu() {
    this.router.navigate(['diet-menu']);
  }
  goToDnaResults() {
    this.router.navigate(['dna-report']);
  }
  goToRecomendations() {
    if (!this.product.bloodTestForBulletsFilled) {
      this.popupHandlerService.openHasNotBloodTest();
    } else {
      this.router.navigate(['report-recomendations']);
    }
  }
  goToLifestyleReport() {
    //  if (false) {
    if (!this.product.patient.lifeStyleReport) {
      this.router.navigate(['lifestyle-report-new']);
    } else if (
      this.product.patient.lifeStyleReport &&
      this.product.patient.lifeStyleReport.isOldActive
      /*
      this.product.patient.lifeStyleReport.value &&
      !this.product.patient.lifeStyleReport.valueNew
      */
    ) {
      this.router.navigate(['lifestyle-report-old']);
    } else {
      this.router.navigate(['lifestyle-report']);
    }
  }
  goToSugarReport() {
    this.router.navigate(['suggar-results']);
  }
  goToBloodTestReport() {
    this.router.navigate(['bloodtests-results-new']);
  }
  isProductCompleted() {
    return this.getProductStatus() == ProductStatusEnum.Completed;
  }
  getProductStatus() {
    //return this.fakeStatusId;
    return this.product.statusId;
  }
  isBloodTestComplete() {
    if (!this.product.patient.bloodTestResults || !this.bloodTestTypes)
      return false;
    if (
      this.product.patient.bloodTestResults.length >= this.bloodTestTypes.length
    )
      return true;
    return false;
  }
  /*
  isSuggerComplete() {
    if (this.product.patient.bloodTestResults == null) return false;
    var suggerTest = this.product.patient.bloodTestResults.filter(
      (x) => x.bloodTestTypeId == 1
    );
    if (suggerTest.length == 1) return true;
    return false;
  }
  */
  isLifeStyleReportComplete() {
    return (
      (this.product.patient.lifeStyleReport &&
        (this.product.patient.lifeStyleReport.isComplete ||
          this.product.patient.lifeStyleReport.isCompleteNew)) ||
      this.product.lifeStyleReportComplete
    );
  }

  isMobile() {
    var a = window.navigator.userAgent;
    var a1 = isMobileAndTable();
    return isMobileAndTable();
  }

  validateWeight(weight) {
    if (weight < 5 || weight > 300 || weight == '' || weight == null)
      return false;

    return true;
  }
  openEnterWieghtDialog() {
    this.weightEdit = !this.weightEdit;

    if (this.weightEdit) {
      setTimeout(() => this.weightInput.nativeElement.focus());
    } else {
      if (this.validateWeight(this.weightHistory)) {
        this.weightHistoryError = false;
        this.patientsService
          .updateWeight(this.patientId, this.weightHistory)
          .subscribe((res) => {
            this.getProduct();
          });
      } else {
        this.weightInput.nativeElement.focus();
        this.weightHistoryError = true;
        this.weightEdit = true;
      }
    }

    //this.popupHandlerService.openEnterWeightDialog(this.weightHistory);
  }
  /* getWieghtImprovment() {
    var startW: number = this.getStartingWeight();
    var currentW: number = this.getCurrentWeight();
    var precentNum: number = Number((startW - currentW) / startW) * 100;
    var precent = Math.round(precentNum) + '%';

    if (startW < currentW) precent = '0%';

    return precent;
  } */
  openWeightHistoryDialog() {
    this.popupHandlerService.openWeightHistoryDialog(
      this.product.patient.patientWeightHistories
    );
  }
  hasMenus() {
    return this.product.menus.some((x) => x.isComplete);
  }
  isNutritionReportExist() {
    if (!this.product) return false;
    var hasMenu = false;
    if (this.product.fileUploads && this.product.fileUploads.length > 0) {
      if (
        this.product.fileUploads.filter(
          (x) => x.fileUploadTypeId == FileUploadTypeEnum.PatientNutritionReport
        ).length > 0
      )
        return true;
    }

    if (this.product.menus && this.product.menus.length > 0) {
      for (var i = 0; i < this.product.menus.length; i++) {
        var menu = this.product.menus[i];
        if (menu.isComplete) {
          return true;
        }
      }
    }
  }
  /*
  isNutritionReportExist() {
    if (!this.product.fileUploads) return false;
    return (
      this.product.fileUploads.filter(
        (x) => x.fileUploadTypeId == FileUploadTypeEnum.PatientNutritionReport
      ).length > 0
    );
  }*/
  isNutroPlus() {
    var a = this.product.productTypeId == ProductTypeEnum.NutrogenicsPlus;
    return this.product.productTypeId == ProductTypeEnum.NutrogenicsPlus;
  }

  get environment() {
    return environment;
  }
  resetProcess() {}
  goToActivityLifestyle() {
    this.router.navigate(['lifestyle-report-new'], {
      queryParams: { locationId: 1 },
    });
  }

  onRestartGoalSession() {
    this.patientsService.RestartGoalSession(this.patientId).subscribe((res) => {
      this.store.dispatch(new LoadProduct({ patientId: this.patientId }));
    });
  }
}
