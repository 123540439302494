<mat-sidenav-container autosize class="sidenav-full" *ngIf="!isMobile()">
  <mat-sidenav
    opened
    #catNav
    mode="side"
    position="end"
    role="navigation"
    class="main-sidenav"
    [disableClose]="true"
    [opened]="showSideNav && !isNotSidenavPage()"
  >
    <app-side-nav [isExapleUser]="isExapleUser"></app-side-nav>
  </mat-sidenav>
  <mat-sidenav-content>
    <div [class.mobile-fix]="lastUrl !== '/chat'">
      <div class="top-bar">
        <img (click)="goToHome()" src="../assets/logo.svg" alt="" />
        <button
          *ngIf="(user$ | async) && isInnerPage() && !isNotSidenavPage()"
          class="top-bar__back"
          (click)="navBack()"
          mat-icon-button
        >
          <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
        <button
          *ngIf="isNotSidenavPage() && (user$ | async)"
          class="top-bar__logout"
          (click)="doLogout()"
          mat-button
        >
          התנתק
        </button>
      </div>
      <router-outlet (activate)="onActivate($event)"></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
<div class="mobile" *ngIf="isMobile()">
  <img class="logo" src="../assets/logo.svg" alt="" />
  <!-- <iframe
    width="560"
    height="315"
    src="https://www.youtube.com/embed/fGdbdWDBXhI"
    title="YouTube video player"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  ></iframe> -->
  <div class="store">
    <strong>נא לשים לב:</strong>
    <p style="text-align: center">
      בימים הקרובים תעלה אפליקציה מתקדמת דרכה ניתן יהיה להיכנס בקלות לאיזור
      האישי שלך. <br />
      כרגע האיזור האישי זמין דרך המחשב בלבד!
      <br /><br /><strong>יש למה לחכות! מבטיחים לעדכן</strong>
    </p>
  </div>
  <div class="store">
    <div>Download from</div>
    <a
      href="https://play.google.com/store/apps/details?id=com.mygenes.myapp"
      target="_top"
    >
      <img *ngIf="isIOS()" src="../assets/app-store.svg" alt="" />
    </a>
    <a
      href="https://play.google.com/store/apps/details?id=com.mygenes.myapp"
      target="_top"
    >
      <img
        (click)="goToPlayStore()"
        *ngIf="!isIOS()"
        src="../assets/play-store.svg"
        alt=""
    /></a>
  </div>
</div>
