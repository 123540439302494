<div class="scan-barcode__wrapper">
  <!-- <div class="scan-barcode__wrapper__logo">
    <img src="../../../assets/logo.svg" alt="" />
    <button
      (click)="doLogout()"
      mat-button
      class="btn btn--outline btn--small btn--center"
    >
      התנתק
    </button>
  </div> -->
  <div *ngIf="product$ | async" class="scan-barcode">
    <app-status-bar
      *ngIf="(product$ | async).statusId !== ProductStatusEnum.SendToCustomer"
      [readyToPickup]="afterSpitting"
      [enteredBarcodeInnerStatus]="innerStatusId"
    ></app-status-bar>
    <div
      [class.w-1200]="
        getProductStatus() == ProductStatusEnum.EnteredBarcode && !afterSpitting
      "
    >
      <div *ngIf="isLoading" class="loadingOverlay">
        <mat-spinner diameter="50"></mat-spinner>
      </div>
      <div class="scan-barcode__title">
        {{ getStatusTitle() }}
      </div>
      <div>
        <ng-container [ngSwitch]="getProductStatus()">
          <ng-container *ngSwitchCase="ProductStatusEnum.SendToCustomer">
            <img
              class="scan-barcode__img"
              src="../../../assets/barcode-error.svg"
            />
            <p style="text-align: center">
              קיימת תקלה בחשבון שלך אנא פנה לשירות הלקוחות
            </p>
            <div
              style="
                font-size: 16px;
                margin-top: 10px;
                margin-bottom: 30px;
                text-align: center;
              "
            >
              לתמיכה וסיוע אנא פנה אל מוקד השירות בטלפון
              <br *ngIf="isMobile()" />
              <a
                href="tel: 03-3095244"
                style="text-decoration: underline; font-weight: 700"
              >
                03-3095244</a
              >
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="ProductStatusEnum.SentToCustomer">
            <div class="scan-barcode__text">
              המשלוח עם ערכת בדיקת הרוק בדרך אליך
            </div>
            <div class="text-center">אנא חזור לאיזור האישי לאחר קבלת הערכה</div>

            <img
              class="scan-barcode__img"
              src="../../../assets/sent-to-customer.svg"
            />

            <button
              (click)="onRecivedDelivery()"
              mat-button
              class="btn btn--fill btn--big"
            >
              קיבלתי את הערכה
            </button>
          </ng-container>

          <ng-container *ngSwitchCase="ProductStatusEnum.ArrivedToCostumer">
            <ng-container *ngIf="enteredBarcodeOnce == false">
              <div class="scan-barcode__title">ברכותינו!</div>
              <div class="scan-barcode__text">קיבלת את ערכת הבדיקה</div>

              <div class="scan-barcode__text scan-barcode__text--sm mt-2">
                <ng-container
                  *ngIf="isApp == true && isFailedScanningBarcode == true"
                >
                  לא הצלחת לסרוק?
                </ng-container>
                להמשך, אנא
                <ng-container
                  *ngIf="isApp == false || isFailedScanningBarcode == true"
                >
                  הזן
                </ng-container>
                <ng-container
                  *ngIf="isApp == true && isFailedScanningBarcode == false"
                >
                  סרוק
                </ng-container>

                את הברקוד שעל מבחנת הרוק
              </div>

              <mat-form-field
                class="insertBarcode"
                *ngIf="isApp == false || isFailedScanningBarcode == true"
              >
                <input
                  placeholder="מספר הברקוד"
                  class="edit-field login-field"
                  matInput
                  [(ngModel)]="firstTimeBarcode"
                  required
                />
                <!--  <mat-error *ngIf="userName.errors && userName.errors.required">
                    אנא הזן תעודת זהות
                  </mat-error> -->
                <small style="color: #f44336" *ngIf="isFailedOnBarcode">
                  הקוד שהזנת אינו תואם, אנא הזן שוב
                </small>
                <small
                  style="color: #f44336"
                  *ngIf="(productError$ | async) && !isFailedOnBarcode"
                >
                  {{ getProductError(productError) }}
                </small>
                <small
                  style="color: #f44336"
                  *ngIf="
                    pleaseFillBarcode == true &&
                    (isApp == false || isFailedScanningBarcode == true)
                  "
                >
                  יש להזין ברקוד
                </small>
              </mat-form-field>
              <img
                style="margin-top: 20px; margin-bottom: 20px; max-width: 100%"
                src="../../../assets/tube.png"
              />
              <br />
              <button
                *ngIf="isApp == false || isFailedScanningBarcode == true"
                [disabled]="isLoading"
                mat-button
                class="btn btn--fill btn--big"
                (click)="onEnterBarcodeFirstTime()"
              >
                המשך
              </button>
              <button
                *ngIf="isApp == true && isFailedScanningBarcode == false"
                [disabled]="isLoading"
                mat-button
                class="btn btn--fill btn--big"
                (click)="onScanBarcode()"
              >
                סריקת ברקוד
              </button>
            </ng-container>

            <ng-container *ngIf="enteredBarcodeOnce == true">
              <div style="margin-top: 50px; margin-bottom: 30px">
                <img style="max-width: 100%" src="../../../assets/tube.png" />
              </div>
              <div class="scan-barcode__title">מעולה!</div>
              <div class="scan-barcode__text">
                רק כדי לוודא כדי שלא טעית במספר הברקוד <br />
                אנא הזן שוב את המספר
              </div>
              <br />
              <mat-form-field class="insertBarcode">
                <input
                  placeholder="מספר הברקוד"
                  class="edit-field login-field"
                  matInput
                  [(ngModel)]="secondTimeBarcode"
                  required
                />
                <small
                  style="color: #f44336; text-align: right"
                  *ngIf="isSubmitedBarcode && !secondTimeBarcode"
                >
                  אנא הכנס/י שוב את מספר הברקוד המופיע על המבחנה
                </small>
              </mat-form-field>
              <button
                [disabled]="isLoading"
                class="btn btn--big btn--fill mt-2"
                mat-button
                (click)="onEnterBarcodeSecondTime()"
              >
                המשך
              </button>
            </ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="ProductStatusEnum.EnteredBarcode">
            <ng-container [ngSwitch]="innerStatusId">
              <ng-container
                *ngSwitchCase="EnteredBarcodeInnerStatus.BeforeSpitting"
              >
                <div class="scan-barcode__title">
                  לקוח יקר!<br />
                  בצע את הבדיקה לפי ההוראות
                </div>
                <div class="scan-barcode__text">
                  <strong>שים לב!</strong> יש לצום שעה לפני ביצוע הבדיקה
                </div>

                <div class="guideVideo">
                  <div class="guideVideo__iframe">
                    <iframe
                      src="https://www.youtube.com/embed/fGdbdWDBXhI"
                      frameborder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                  <div class="guideVideo__title">
                    לחץ לצפייה בסרטון הסבר לביצוע הבדיקה
                  </div>
                </div>

                <button
                  mat-button
                  #nextBtn
                  class="btn btn--big btn--fill"
                  (click)="afterSpittingBtn()"
                >
                  המשך
                </button>
                <!-- <div class="mt-2 text-center">
                  <button
                    mat-button
                    class="btn btn--underline btn--small btn--primary btn--center fw-light"
                    [routerLink]="['/chat']"
                    [queryParams]="{ fromScanBarcode: true }"
                  >
                    לתמיכה לחץ כאן
                  </button>
                </div> -->
              </ng-container>
              <ng-container
                *ngSwitchCase="EnteredBarcodeInnerStatus.AfterSpitting"
              >
                <div class="scan-barcode__title">שנבוא לקחת?</div>
                <div class="scan-barcode__text">
                  לאחר שביצעת את הבדיקה עליך לתאם שליח לצורך איסוף המבחנה למעבדה
                </div>
                <img
                  src="../../../assets/entered-barcode.svg"
                  alt="entered-barcode"
                  class="scan-barcode__img"
                />
                <button
                  [disabled]="isLoading"
                  (click)="gotoConfirmAdress()"
                  class="btn btn--big btn--fill mt-2"
                  mat-button
                >
                  לחץ לתיאום איסוף
                </button>
                <!--    (click)="onOrderDelivery()"-->
                <br />
                <br />
                <div class="scan-barcode__text mt-2">
                  אם עדיין לא ביצעת את הבדיקה
                  <span
                    style="
                      text-decoration: underline;
                      font-weight: 600;
                      cursor: pointer;
                    "
                    (click)="
                      innerStatusId = EnteredBarcodeInnerStatus.BeforeSpitting
                    "
                  >
                    לחץ כאן</span
                  ><br />
                  כדי לחזור לשלב ביצוע הבדיקה
                </div>

                <div class="scan-barcode__text mt-2">
                  לתמיכה וסיוע אנא פנה אל מוקד השירות
                  <br *ngIf="isMobile()" />בטלפון
                  <a
                    href="tel: 03-3095244"
                    style="text-decoration: underline; font-weight: 600"
                  >
                    03-3095244</a
                  >
                  <!-- <br />
                  או
                  <a style="text-decoration: underline; font-weight: 600">
                    לחץ כאן</a
                  >
                  לפתיחת פנייה מקוונת -->
                </div>
              </ng-container>
              <ng-container
                *ngSwitchCase="EnteredBarcodeInnerStatus.ConfirmAdress"
              >
                <div class="scan-barcode__text">
                  <div class="address">
                    <div class="address__text">
                      אנא אשר את כתובתך לאיסוף הערכה
                      <br />
                      ניתן לשנות את כתובת האיסוף בהתאם לנוחיותך
                    </div>

                    <div class="address__item">
                      <div class="address__item__label">כתובת למשלוח</div>
                      <mat-form-field
                        class="address__item__field"
                        appearance="fill"
                      >
                        <mat-select [(ngModel)]="selectedAdressType">
                          <mat-option [value]="1">{{
                            getUserAdressString(product$ | async)
                          }}</mat-option>
                          <mat-option [value]="2">כתובת חדשה</mat-option>
                        </mat-select>
                        <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                      </mat-form-field>
                    </div>
                    <ng-container *ngIf="selectedAdressType == 2">
                      <div class="address__item">
                        <div class="address__item__label">עיר</div>
                        <div class="address__item__field" appearance="fill">
                          <app-autocomplete-address
                            [initialValue]=""
                            [label]="'עיר'"
                            [data]="mapCities(citiesResults$ | async)"
                            (selectedValue)="onCitySelected($event)"
                            (searchTerm)="searchTermCity$.next($event)"
                          ></app-autocomplete-address>
                        </div>
                      </div>
                      <div class="address__item">
                        <div class="address__item__label">רחוב</div>
                        <div class="address__item__field" appearance="fill">
                          <app-autocomplete-address
                            [initialValue]="
                              newAddress.barStreet && newAddress.barStreet.name
                            "
                            [label]="'רחוב'"
                            [data]="mapStreets(streetsResults$ | async)"
                            (selectedValue)="onStreetSelected($event)"
                            (searchTerm)="searchTermStreet$.next($event)"
                          ></app-autocomplete-address>
                        </div>
                      </div>
                      <div class="address__item address__item--grid-3">
                        <div>
                          <div class="address__item__label">מספר רחוב</div>
                          <mat-form-field
                            class="address__item__field"
                            appearance="fill"
                          >
                            <input
                              matInput
                              type="number"
                              [(ngModel)]="newAddress.streetNum"
                            />
                          </mat-form-field>
                        </div>
                        <div>
                          <div class="address__item__label">כניסה</div>
                          <mat-form-field
                            class="address__item__field"
                            appearance="fill"
                          >
                            <input
                              matInput
                              type="number"
                              [(ngModel)]="newAddress.entrance"
                            />
                          </mat-form-field>
                        </div>
                        <div>
                          <div class="address__item__label">מס' דירה</div>
                          <mat-form-field
                            class="address__item__field"
                            appearance="fill"
                          >
                            <input
                              matInput
                              type="number"
                              [(ngModel)]="newAddress.apartment"
                            />
                          </mat-form-field>
                        </div>
                      </div>
                      <div>
                        <div class="address__item__label">הערות משלוח</div>
                        <mat-form-field
                          class="address__item__field textarea"
                          appearance="fill"
                        >
                          <textarea
                            matInput
                            [(ngModel)]="newAddress.shipNotes"
                            cols="30"
                            rows="2"
                          ></textarea>
                        </mat-form-field>
                      </div>
                    </ng-container>
                  </div>
                </div>

                <button
                  mat-button
                  class="btn btn--fill btn--big"
                  [disabled]="!validChangeAddress()"
                  (click)="editAdressConfirm()"
                >
                  אישור
                </button>
                <div class="mt-4 text-center">
                  <div class="help-text">עדיין לא ביצעת את הבדיקה?</div>
                  <div class="help-text">
                    <button
                      mat-button
                      class="btn btn--underline btn--small btn--center p-0 primary-color"
                      (click)="
                        innerStatusId = EnteredBarcodeInnerStatus.BeforeSpitting
                      "
                    >
                      לחץ כאן
                    </button>
                    וחזור לשלב ביצוע הבדיקה
                  </div>
                  <hr class="hr" />
                  <div class="mt-2">
                    לתמיכה וסיוע אנא פנה אל מוקד השירות
                    <br *ngIf="isMobile()" />
                    בטלפון
                    <a
                      href="tel: 03-3095244"
                      style="text-decoration: underline; font-weight: 700"
                    >
                      03-3095244</a
                    >
                  </div>
                </div>
              </ng-container>
              <ng-container
                *ngSwitchCase="EnteredBarcodeInnerStatus.ChangeAdress"
              ></ng-container>
            </ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="ProductStatusEnum.WaitingToPickUp">
            <!--<div style="margin-bottom: 20px;">השליח בדרך אליך</div>-->
            <div class="scan-barcode__title scan-barcode__title--big">
              בשעות הקרובות תקבל SMS
              <br />
              לקביעת מועד איסוף הערכה
            </div>
            <div class="scan-barcode__text">
              תוצאות הבדיקה יתקבלו עד 45 ימי עסקים לאחר האיסוף.
            </div>
            <div class="scan-barcode__text">
              נעדכן אותך לאורך התהליך באימייל ו SMS.
            </div>
            <img
              src="../../../assets/wating-to-pickup.svg"
              alt="entered-barcode"
              class="scan-barcode__img"
            />
            <button
              [routerLink]="['/profile']"
              mat-button
              class="btn btn--big btn--fill mt-2"
            >
              לאיזור האישי
            </button>
            <!-- <div
              style="
                text-align: center;
                width: 85%;
                margin: 20px auto;
                font-size: 1.6rem;
              "
            >
              <div style="font-size: 1.8rem; margin-bottom: 5px">
                <strong>בנתיים..</strong>
              </div>
              <p>
                אנו מזמינים אותך לצפות
                <a
                  target="_blank"
                  style="text-decoration: underline"
                  href="https://mygenes.co.il/%d7%a1%d7%99%d7%a4%d7%95%d7%a8%d7%99-%d7%94%d7%a6%d7%9c%d7%97%d7%94/"
                  ><strong>בסיפורי הצלחה</strong></a
                >
                של לקוחות שביצעו את הבדיקה הנוטרוגנטית המתקדמת בעולם.
              </p>
            </div>
            <hr style="width: 80%; margin: 4rem auto" /> -->
          </ng-container>

          <!-- <ng-container
            *ngSwitchCase="
              ProductStatusEnum.ArrivedToLab || ProductStatusEnum.Completed
            "
          >
          </ng-container> -->
        </ng-container>

        <!-- <ng-container
          *ngIf="
            getProductStatus() == ProductStatusEnum.WaitingToPickUp ||
            afterSpitting == true
          "
        >
          <app-status-bar></app-status-bar>
        </ng-container> -->
      </div>
    </div>
  </div>
</div>
