import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Observable } from 'rxjs';
import { Language } from '../data/Language';
import { LifestyleAnswerMin } from '../data/LifestyleAnswerMin';
import { LifestyleQuestion } from '../data/LifestyleQuestion';
import { Product } from '../data/Product';
import { QuestionDesignType } from '../Enum/StatusesEnum';
import { LoadProduct } from '../Redux/Actions/product.actions';
import { AppState } from '../Redux/reducers';
import {
  selectPatientId,
  selectProduct,
  lifeStyleReportComplete,
} from '../Redux/selectors/product.selectors';
import { LanguagesService } from '../Services/languages.service';
import { LifestyleQuestionsService } from '../Services/lifestyle-questions.service';
import { ProductService } from '../Services/product.service';
import { RegularUsersService } from '../Services/regular-users.service';
import { refAnsweredUtil, isValidUtil } from '../Utils/lifestyleUtil';
import { isPremium } from '../Utils/userHelper';

@Component({
  selector: 'app-life-style-report-app',
  templateUrl: './life-style-report-app.component.html',
  styleUrls: ['./life-style-report-app.component.scss'],
})
export class LifeStyleReportAppComponent implements OnInit {
  config: PerfectScrollbarConfigInterface = {
    wheelSpeed: 0.5,
  };
  isDone: boolean = false;
  @Output('updateLifestlyeDone') updateLifestlyeDone: EventEmitter<any> =
    new EventEmitter<any>();
  patientId$: Observable<number> = this.store.select(selectPatientId);
  product$: Observable<Product> = this.store.select(selectProduct);
  lifeStyleReportComplete$: Observable<boolean> = this.store.select(
    lifeStyleReportComplete
  );
  productId: number;
  langId: number = 1;
  langs: Language[];
  showIndex: number = 0;
  lifestyleQuestions: LifestyleQuestion[];
  isLoading: boolean;
  locationId: number = 0;
  patientId: number;
  token: string;
  constructor(
    private lifestyleQuestionsService: LifestyleQuestionsService,
    private route: ActivatedRoute,
    private lansService: LanguagesService,
    private store: Store<AppState>,
    private router: Router,
    private productsService: ProductService,
    private regularUsersService: RegularUsersService
  ) {}

  ngOnInit(): void {
    this.token = localStorage.getItem('tokenApp');
    this.patientId = +localStorage.getItem('patientId');
    if (!this.token) {
      return;
      ///this.router.navigate(['login']);
    }
    this.productsService
      .getByPatientIdForApp(this.patientId, this.token)
      .subscribe((data) => {
        this.productId = data.productId;
        this.patientId = data.patientId;
        this.getLangs();
        this.getQuestions();
      });

    /*  this.product$.subscribe((product) => {
      if (product) {
        this.productId = product.productId;
        if (!isPremium(product.productTypeId)) {
          this.router.navigate(['/home']);
        } else {
          this.getQuestions();
        }
      }
    }); */
    /*this.patientId$.subscribe((patientId) => (this.patientId = patientId)); */
    this.lifeStyleReportComplete$.subscribe((isDone) => (this.isDone = isDone));
  }

  getLangs() {
    this.lansService
      .getAllForApp(this.token)
      .subscribe((data) => (this.langs = data));
  }

  onIcrementIndex(index) {
    //console.log('onIcrementIndex', index);
    //this.setIndex(index);
  }

  getQuestions() {
    this.lifestyleQuestionsService
      .getByProductIdForApp(
        this.productId,
        this.langId,
        this.locationId,
        this.token
      )
      .subscribe((data) => {
        this.lifestyleQuestions = data;
        this.setIndex(this.showIndex);
      });
  }

  hasRefId(question: LifestyleQuestion) {
    if (question.referenceQuestionId) {
      return true;
    }
    return false;
  }
  refAnswered(refId) {
    //console.log('refAnswered from root');
    return refAnsweredUtil(this.lifestyleQuestions, refId);
  }
  isValid(node: LifestyleQuestion) {
    return isValidUtil(node);
  }
  isToShow(node: LifestyleQuestion) {
    if (
      node.referenceQuestionId &&
      !this.refAnswered(node.referenceQuestionId)
    ) {
      return true;
    }
    //console.log('isToShow 1' + node);
    if (
      (!node.lifeStyleAnswers || node.lifeStyleAnswers.length == 0) &&
      node.designTypeId !== QuestionDesignType.header &&
      node.designTypeId !== QuestionDesignType.headerCenter &&
      node.designTypeId !== QuestionDesignType.successThumb
    ) {
      return false;
    }
    //console.log('isToShow 2' + node);
    if (!node.childQuestions || node.childQuestions.length == 0) {
      //  console.log('isValid');

      return true;
    }
    if (node.childQuestions && node.childQuestions.length > 0) {
      let validChildCount = 0;
      for (let index = 0; index < node.childQuestions.length; index++) {
        const child = node.childQuestions[index];
        if (this.isValid(child)) {
          validChildCount++;
        }
      }
      if (validChildCount == 0) {
        return false;
      }
    }

    //console.log('is valid node:', node.lifestyleQuestionId);

    return true;
  }
  setIndex(index) {
    //console.log('setIndex', index);

    this.showIndex = 0;
    while (
      this.lifestyleQuestions[this.showIndex] &&
      this.isToShow(this.lifestyleQuestions[this.showIndex])
    ) {
      //  if (this.isValid(this.lifestyleQuestions[this.showIndex])) {
      if (true) {
        // console.log('setIndex2', this.showIndex);
        if (this.lifestyleQuestions[this.showIndex].referenceQuestionId) {
          if (
            this.refAnswered(
              this.lifestyleQuestions[this.showIndex].referenceQuestionId
            )
          ) {
            this.showIndex++;
          } else {
            this.showIndex += 1;
          }
        } else {
          this.showIndex++;
        }
        //console.log('setIndex end', this.showIndex);
      }
    }
    if (
      this.locationId == 0 &&
      this.showIndex == this.lifestyleQuestions.length &&
      !this.isDone
    ) {
      this.productsService
        .setLifeStyleNewCompleteForApp(this.productId, true, this.token)
        .subscribe((res) => {
          this.isDone = true;
          //this.store.dispatch(new LoadProduct({ patientId: this.patientId }));
        });
    }
  }
  trackByQuestionId(index, question: LifestyleQuestion) {
    return question ? question.lifeStyleReportVersionId : undefined;
  }

  OnUpdateAnswer(event) {
    this.isLoading = true;
    this.lifestyleQuestionsService
      .updateAnswerForApp(
        this.productId,
        event.questionId,
        event.inputTypeId,
        event.value,
        this.token
      )
      .subscribe(
        (answers) => {
          this.lifestyleQuestions = this.mergeQuestionsWithAnswers(
            answers,
            this.lifestyleQuestions
          );
          this.setIndex(this.showIndex);
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
        }
      );
  }
  mergeQuestionsWithAnswers(
    answers: LifestyleAnswerMin[],
    questions: LifestyleQuestion[]
  ) {
    for (let index = 0; index < questions.length; index++) {
      const element: any = questions[index];

      element.lifeStyleAnswers = answers.filter(
        (x) => x.lifestyleQuestionId == element.lifestyleQuestionId
      );
      if (!element.lifeStyleAnswers) element.lifeStyleAnswers = [];
      if (element.childQuestions)
        element.childQuestions = this.mergeQuestionsWithAnswers(
          answers,
          element.childQuestions
        );
    }
    return questions;
  }
  convertAnwerMintoAnswer(answerMin: LifestyleAnswerMin) {}
  getProgressBarMaxInx() {
    return Math.floor(this.lifestyleQuestions.length / 5) * 5;
  }
  finishAnswering() {
    this.store.dispatch(new LoadProduct({ patientId: this.patientId }));
    this.router.navigate(['home']);
  }
}
